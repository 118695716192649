<template>
    <div v-if="checkFilter()" class="line">
        <div class="pre-date">
            <span class="date">{{new Date(log.date).toISOString().substring(0, 16).replace('T', ' ')}}</span><br>
            <span class="cause">{{cause}}</span>
        </div>
        <div style="display: inline-block; vertical-align: top; margin-left: 8px; margin-top: 2px;">
            <span>{{log.data}}</span>
        </div>
    </div>
</template>

<script>
export default {
  props: {
    part:Number,
    log:Object,
    filterAction:String,
    filterAccount:String
  },
  computed: {
      cause() {
          switch(this.log.cause) {
              case 0: return this.log.target; 
              case 1: return 'Profile'; 
              case 2: return 'Client';   
              default: return 'Unknown';
          }
      }
  },
  methods: {
      checkFilter: function() {
          if(this.filterAction != 'all')
            if(this.filterAccount != 'all') return this.filterAccount == this.log.target;
            else return parseInt(this.filterAction) == this.log.cause;
          
          if(this.filterAccount != 'all')
            return this.filterAccount == this.log.target;
          return true;
      }
  }
}
</script>

<style scoped>
    .date {
        font-size: 12px;
        opacity: 0.7;
    }
    .cause {
        font-size: 12px;
        font-weight: bold;
        vertical-align: top;
        opacity: 0.7;
    }
    .pre-date {
        display: inline-block;
        user-select: none;
    }
    .line {
        cursor: default;
    }
    .line:hover {
        background: #eaeaea;
    }
</style>
const signalR = require('@aspnet/signalr')

module.exports = {
    // https://api-socket.afkadvantage.com/backend : http://127.0.0.1:5122/backend
    connection: new signalR.HubConnectionBuilder().withUrl('https://api-socket.afkadvantage.com/backend').withAutomaticReconnect().build(),
    start: () => {
        // After hooking everything start the connection.
        var connection = module.exports.connection;
        connection.on('loginRequire', ()=> {
            window.location.replace("/auth/login");
        })
        
        connection.onclose(function() {
            setTimeout(() => {
                module.exports.start();
            }, 10000);
        })

        module.exports.connection.start().then(() => {
            connection.invoke('Authenticate', {});
        });

        setTimeout(() => {
            if(!connection.connectionStarted) module.exports.start();
        }, 10000);
    },
    sync: () => {
        module.exports.connection.invoke('Sync', {});
    },
    pushSettings: (settings, profile) => {
        settings.Profile = profile;
        settings.generic.speed = parseInt(settings.generic.speed);
        module.exports.connection.invoke('PushSettings', settings);
    },
    selectClientPath: () => {
        module.exports.connection.invoke('SelectClientPath');
    },
    setStartState: (profile, state) => {
        module.exports.connection.invoke('SetStartState', { profile: profile, Start: state });
    }
}
<template>
  <div>
    <div class="menu" v-bind:disabled="disabled" v-bind:style="[hide ? {visibility: 'hidden'} : {}]">
      <router-link to="./dash" :event="!disabled ? 'click' : ''" v-bind:disabled="disabled" v-bind:class="{'menu-entry':true, 'menu-active':$route.params.page=='dash'}">
        <div class="menu-entry-title">
          <img src="~@/assets/img/menu/icon-activity.svg" alt="activity" />Dashboard
        </div>
      </router-link>
      <router-link to="./accounts" :event="!disabled ? 'click' : ''" v-bind:disabled="disabled" v-bind:class="{'menu-entry':true, 'menu-active':$route.params.page=='accounts'}">
        <div class="menu-entry-title">
          <img src="~@/assets/img/menu/icon-people.svg" alt="people" />Accounts
        </div>
      </router-link>
      <div class="menu-entry">
        <div class="menu-entry-title">
          <img src="~@/assets/img/menu/icon-plane.svg" alt="plane" />Controls
        </div>
        <div class="menu indented">
          <router-link to="./modules" :event="!disabled ? 'click' : ''" v-bind:disabled="disabled" v-bind:class="{'menu-entry':true, 'menu-active':$route.params.page=='modules'}">
            <div class="menu-entry-title">
              <img src="~@/assets/img/menu/icon-code.svg" alt="code" />Modules
            </div>
          </router-link>
          <router-link to="./settings" :event="!disabled ? 'click' : ''" v-bind:disabled="disabled" v-bind:class="{'menu-entry':true, 'menu-active':$route.params.page=='settings'}">
            <div class="menu-entry-title">
              <img src="~@/assets/img/menu/icon-settings.svg" alt="clipboard" />Settings
            </div>
          </router-link>
        </div>
      </div>
      <div class="menu-entry">
        <div class="menu-entry-title">External</div>
        <div class="menu indented">
          <router-link to="./discord" :event="!disabled ? 'click' : ''" v-bind:disabled="disabled" v-bind:class="{'menu-entry':true, 'menu-active':$route.params.page=='discord'}">
            <div class="menu-entry-title">
              <img src="~@/assets/img/menu/icon-discord.svg" alt="discord" />Discord
            </div>
          </router-link>
        </div>
      </div>
    </div>

    <select required class="vertical-menu" v-model="verticalMenuValue" v-on:change="onVerticalMenuChanged">
      <option value="" disabled selected hidden>Menu</option>
      <option value="dash">Dashboard</option>
      <option value="accounts">Accounts</option>
      <option value="modules">Modules</option>
      <option value="settings">Settings</option>
      <option value="discord">Discord</option>
    </select>
  </div>
</template>

<script>
export default {
    props: {
        disabled: Boolean,
        hide: Boolean
    },
    data() {
      return {
        verticalMenuValue: ''
      }
    },
    methods: {
      onVerticalMenuChanged: function() {
        this.$router.push(`./${this.verticalMenuValue}`);
      }
    }
}
</script>

<style scoped>
@import '../../../assets/css/dashboard.css';
</style>
<template>
  <div class="app" v-if="!showEmailVerification">
    <Navbar
      v-bind:showProfile="this.profile"
      v-bind:profiles="this.profiles"
      v-bind:profile="this.profile"
      v-bind:account="this.account"
    />
    <div class="content">
      <Menu v-bind:disabled="!this.profile" />

      <div v-if="!this.loading">
        <NoClient v-if="!connection || !connection.connected" />
        <div v-else>
          <Profiles v-if="!this.profile" v-bind:profiles="profiles" />
          <Controls
            v-else
            v-bind:profiles="profiles"
            v-bind:connection="connection"
            v-bind:profile="profile"
            v-bind:serverConnection="serverConnection"
          />
        </div>
      </div>
      <div v-else style="text-align: center;">
        <div class="loader"></div>
      </div>
    </div>
  </div>
  <div class="app" v-else>
    <div class="email-verification-box">
      <div style="text-align: center;">
        <img src="~@/assets/logo.png"/>
      </div>
      <div>
        <strong>We have sent an email to <strong style="color: #148EFF;">"{{this.account.email}}"</strong> to confirm that you own it. Please follow the instructions that we have sent to your inbox to confirm.</strong><br/>
        <span>The email can take up to 5 minutes to arrive to your inbox. If you have not received the email please check your Spam inbox.</span>
      </div>
      <div class="email-verification-links">
        <router-link to="/account/signout">Don't own that email? Sign out</router-link>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from "../components/global/navbar/Navbar";
import Menu from "../components/global/navbar/Menu";

import Profiles from "../components/main/profile/ProfileDisplay";
import Controls from "../components/main/control/Controls";

import NoClient from '../components/main/control/NoClient';

import SocketApi from "../scripts/socketApi";

export default {
  components: {
    Navbar,
    Menu,
    Profiles,
    Controls,
    NoClient
  },
  data() {
    return {
      loading: true,
      showEmailVerification: false,
      profile: undefined,
      profiles: [ ],
      connection: { id: "", connected: false },
      account: undefined
    };
  },
  computed: {
    serverConnection: function() {
      return SocketApi.connection;
    }
  },
  created() {
    this.matchProfile();
    this.beginConnection();
  },
  watch: {
    $route(to, from) {
      console.log(to, from);
      this.matchProfile();
    },
  },
  methods: {
    matchProfile: function () {
      this.profiles.forEach((profile) => {
        if (profile.id == this.$route.params.id) {
          this.profile = profile;
        }
      });
    },
    beginConnection: function () {
      SocketApi.connection.on("NetworkSync", (syncObject) => {
        this.profiles = syncObject.profiles.map((x) => {
          x.data.active = x.isActive;
          x.data.state = x.state;
          x.data.dashboard = x.dashboard;
          x.data.logs = x.logs;
          return x.data
        });
        this.connection.connected = syncObject.isClientConnected;
        this.loading = false;
        this.matchProfile();
      });
      SocketApi.connection.on("NetworkOnConnected", () => {
        this.connection.connected = true;
      });
      SocketApi.connection.on("NetworkOnDisconnected", () => {
        this.connection.connected = false;
      });
      SocketApi.connection.on("SetAccount", (account) => {
        this.account = account;
        if(!account.emailVerified) {
          this.showEmailVerification = true;
        }
      });
      SocketApi.connection.on("SetAccountTaskState", (data) => {
        this.profiles.forEach((profile) => {
          if(profile.id == data.profile) {
            profile.state.accounts[data.account].task = data.task;
            profile.state.accounts[data.account].taskState = data.taskState;
          }
        });
      });
      SocketApi.connection.on("SetAccountState", (data) => {
        this.profiles.forEach((profile) => {
          if(profile.id == data.profile) {
            profile.state.accounts[data.account].gameData = data.gameData;
          }
        });
      });

      try {
        if(SocketApi.connection.connectionState == 'Disconnected') SocketApi.start();
        else SocketApi.sync();
      } catch { /* */ }
    },
   refreshProfiles: function() {
     this.loading = true;
     this.profile = undefined;
     this.profiles = [];
     this.connection = { id: "", connected: false };
     this.beginConnection();
   } 
  },
};
</script>

<style scoped>
@import "../assets/css/dashboard.css";
</style>
<template>
  <div class="main-view">
    <div class="row-wrapper full">
      <div class="widget manage-access">
        <div class="widget-content">
          <div class="widget-title manage-access">
            General settings
          </div>
          <div class="widget-text manage-access">
            <p>Settings can be edited while the bot is running, however the changes may not be reflected immediately.</p>
          </div>

          <div style="margin-top: 40px;">
            <div>
              <div class="widget-input">
                <select v-model="profile.settings.generic.speed" v-on:change="pushSettings">
                  <option value="0">Fast</option>
                  <option value="1">Medium</option>
                  <option value="2">Slow</option>
                </select>
                <div class="widget-input-name">BOT SPEED</div>
              </div>
            </div>

            <div class="manage-access-generate-wrapper">
              <div class="widget-input">
                <input readonly type="text" placeholder="MEmu directory..." v-model="profile.settings.generic.clientDirectory" />
              </div>
              <button v-on:click="selectClientPath()" class="button-primary">Change Directory</button>
            </div>

            <div class="manage-access-generate-wrapper">
              <div class="widget-input">
                <input readonly type="text" placeholder="Bots selected..." v-bind:value="profile.accounts.length + ' bot config(s) selected'" />
              </div>
              <router-link v-bind:to="'/'+profile.id + '/accounts/edit'"><button class="button-primary" style="width: 100%">Edit</button></router-link>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row-wrapper full">
      <div class="widget manage-access">
        <div class="widget-content">
          <div class="widget-title manage-access">
            Danger zone
          </div>
          <div class="widget-text manage-access">
            <p>Deleting a profile is permanent.</p>
          </div>

          <div style="margin-top: 40px;">
            <span v-on:click="this.deleteProfile" class="delete-permanent-action">Delete "{{profile.name}}" profile</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Api from '../../../scripts/api';
import SocketApi from '../../../scripts/socketApi';

export default {
  props: {
    profile:Object
  },
  methods: {
    deleteProfile: async function() {
      if(confirm("You are about to delete your '"+this.profile.name+"' profile. By clicking 'OK' the profile will be removed permanently.")) {
        var result = await Api.profileDelete(this.profile.id);
        if(result.data.success) {
          this.$router.push('/');
          this.$parent.$parent.refreshProfiles();
        }
      }
    },
    pushSettings: function() {
      SocketApi.pushSettings(this.profile.settings, this.$route.params.id);
    },
    selectClientPath: function() {
      SocketApi.selectClientPath();
    }
  }
}
</script>

<style scoped>
@import "../../../assets/css/dashboard.css";
</style>
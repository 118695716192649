import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/auth/login',
    name: 'Login',
    component: () => import('../views/auth/CreateLogin.vue')
  },
  {
    path: '/auth/register',
    name: 'Register',
    component: () => import('../views/auth/CreateRegister.vue')
  },
  {
    path: '/auth/forgot-password',
    name: 'Forgot Password',
    component: () => import('../views/auth/CreateForgotPassword.vue')
  },
  {
    path: '/auth/reset-password/:key',
    name: 'Reset Password',
    component: () => import('../views/auth/CreateResetPassword.vue')
  },

  {
    path: '/policy/refund-policy',
    name: 'Refund Policy',
    component: () => import('../views/policy/RefundPolicy.vue')
  },
  {
    path: '/policy/terms-of-service',
    name: 'Terms Of Service',
    component: () => import('../views/policy/TermsOfService.vue')
  },
  
  {
    path: '/account/confirm-email',
    name: 'Confirm Email',
    component: () => import('../views/account/ConfirmEmail.vue')
  },
  {
    path: '/account/confirm-email/:hash',
    name: 'Confirm Email',
    component: () => import('../views/account/ConfirmEmail.vue')
  },
  {
    path: '/account/overview',
    name: 'Account',
    component: () => import('../views/account/Account.vue')
  },
  {
    path: '/account/signout',
    name: 'Signout',
    component: () => import('../views/account/Signout.vue')
  },

  {
    path: '/profile/create',
    name: 'Create Profile',
    component: () => import('../views/profile/CreateProfile.vue')
  },

  {
    path: '/',
    name: 'Index',
    component: Home
  },
  {
    path: '/:id/accounts/edit',
    name: 'Index',
    component: () => import('../views/profile/CreateProfile.vue')
  },
  {
    path: '/:id/:page',
    name: 'Index',
    component: Home
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router

<template>
    <div v-if="!connection || !connection.connected" class="main-view">
        <NoClient/>
    </div>    
    <div v-else class="main-view">
        <Dashboard v-if="$route.params.page == 'dash'" v-bind:profile="profile" v-bind:serverConnection="serverConnection" />
        <Accounts v-if="$route.params.page == 'accounts'" v-bind:profile="profile" />
        <Modules v-if="$route.params.page == 'modules'" v-bind:profile="profile" />
        <Settings v-if="$route.params.page == 'settings'" v-bind:profile="profile" />
        <Discord v-if="$route.params.page == 'discord'" v-bind:profile="profile" />
    </div>
</template>

<script>
import Dashboard from './Dashboard';
import Accounts from './Accounts';
import Modules from './Modules';
import Settings from './Settings';
import Discord from './Discord';
import NoClient from './NoClient';

export default {
    components: {
        Dashboard,
        Accounts,
        Modules,
        Settings,
        Discord,
        NoClient
    },
    props: {
        connections: Array,
        connection: Object,
        profile: Object,
        serverConnection: Object
    },
}
</script>
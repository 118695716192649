<template>
  <div class="main-view">
    <div class="row-wrapper quarter">
      <div class="widget statistics">
        <div class="widget-content">
          <div class="widget-title">Accounts</div>
          <table class="widget-table">
            <tr>
              <th>Connected accounts</th>
              <td>{{profile.dashboard.connectedAccounts}}</td>
            </tr>
            <tr style="visibility: hidden;">
              <th>Disconnected alts</th>
              <td>{{disconnectedAccounts}}</td>
            </tr>
          </table>
        </div>
      </div>

      <div class="widget statistics">
        <div class="widget-content">
          <div class="widget-title">Uptime</div>
          <table class="widget-table">
            <tr>
              <th>Running for</th>
              <td>{{getRunningTimeText()}}</td>
            </tr>
            <tr>
              <th>Disconnects</th>
              <td>{{disconnectedAccounts}}</td>
            </tr>
          </table>
        </div>
      </div>

      <div class="widget statistics">
        <div class="widget-content">
          <div class="widget-title">Game</div>
          <table class="widget-table">
            <tr>
              <th>Name</th>
              <td>{{profile.dashboard.game}}</td>
            </tr>
            <tr>
              <th>Version</th>
              <td>1.0.0</td>
            </tr>
          </table>
        </div>
      </div>

      <div class="widget statistics">
        <div class="widget-content">
          <div class="widget-title">System</div>
          <table class="widget-table">
            <tr>
              <th>Version</th>
              <td>{{profile.dashboard.system.version}}</td>
            </tr>
            <tr>
              <th>Last Update</th>
              <td>{{getLastUpdateText()}}</td>
            </tr>
          </table>
        </div>
      </div>
    </div>

    <div class="row-wrapper full">
      <div class="widget actions">
        <div class="widget-content">
          <div class="action">
            <img
              class="action-icon"
              src="~@/assets/img/action/eye-outline.svg"
              alt="visibility"
              style="width: 22px;"
            />
            <div class="action-content">
              <div class="action-title">View MEmu clients</div>
              <div class="action-description">
                Use this feature to see the MEmu client screen from any device. This will stream a view from the clients.
              </div>
            </div>
            <button class="action-button plus"></button>
          </div>

          <div class="row-wrapper quarter">
            <div class="action-wrapper">
              <input type="button" class="button-primary" v-bind:value="(profile.state && profile.state.isActive) ? 'Stop' : 'Start'" v-bind:disabled="this.startButtonDisabled" v-on:click="toggleStart" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row-wrapper full">
        <div class="widget actions" style="background: #EDEDED;">
            <div class="widget-content">
                <div class="row-wrapper quarter" style="grid-template-columns: repeat(6, 1fr);">
                    <div class="action-wrapper">
                        <select v-model="filterAction">
                            <option value="all">Everything</option>
                            <option value="1">Profile Events</option>
                            <option value="2">Client Events</option>
                            <option value="0">Account Events</option>
                        </select>
                    </div>

                    <div class="action-wrapper">
                        <select v-model="filterAccount">
                            <option value="all">Everyone</option>
                            <option v-for="account in profile.accounts" v-bind:key="account.id" v-bind:value="account.name" v-text="account.name" />
                        </select>
                    </div>
                </div>
                <div class="action" style="height: 30vh; overflow-y: auto; display: block;">
                    <LogEntry v-for="log in profile.logs" v-bind:key="log.id" v-bind:log="log" v-bind:filterAction="filterAction" v-bind:filterAccount="filterAccount" />
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>

import SocketApi from '../../../scripts/socketApi';
import LogEntry from './Dashboard/LogEntry';

export default {
  props: {
    profile: Object,
    serverConnection: Object
  },
  components: {
    LogEntry
  },
  data() {
    return {
      startButtonDisabled: false,
      filterAction: 'all',
      filterAccount: 'all',
      updateTicker: 0
    }
  },
  created() {
    if(this.serverConnection.methods.logsadd == undefined) {
      this.serverConnection.on('LogsAdd', (log) => {
        if(log.profile == this.$route.params.id)
          this.profile.logs.unshift(log);
      });
    }
    this.updateTick();
  },
  computed: {
    disconnectedAccounts: function() {
      return this.profile?.state?.disconnects ?? 0
    }
  },
  methods: {
    updateTick: function() {
      setInterval(() => {
        this.updateTicker++;
      }, 10000);
    },
    getLastUpdateText: function() {
      console.log(this.updateTicker); // required in order to refresh the display.
      var date = new Date(this.profile.dashboard.system.lastUpdate);
      
      var yesterday = new Date(Date.now()); yesterday.setDate(yesterday.getDate() - 1);
      var week = new Date(Date.now()); week.setDate(week.getDate() - 7);
      var month = new Date(Date.now()); month.setDate(month.getDate() - 31);
      
      if(yesterday < date) return 'today';
      if(week < date) return 'this week';
      if(month < date) return 'this month';
      return '> 1 month';
    },
    getRunningTimeText: function() {
      if(!this.profile.active) return '(not running)';
      var runningTime = Math.abs(new Date(this.profile.state.dateStarted) - new Date(Date.now())) / 1000;

      var days = Math.floor(runningTime / 86400);
      runningTime -= days * 86400;

      var hours = Math.floor(runningTime / 3600) % 24;
      runningTime -= hours * 3600;

      var minutes = Math.floor(runningTime / 60) % 60;
      runningTime -= minutes * 60;

      return `${(days > 0 ? `${days} day(s) ` : '')}${(hours > 0 ? `${hours} hour(s) ` : '')}${`${minutes} minute(s)`}`;
    },
    toggleStart: function() {
      this.startButtonDisabled = true;

      SocketApi.setStartState(this.$route.params.id, !this.profile.state.isActive);
      this.profile.state.isActive = !this.profile.state.isActive;

      setTimeout(()=> {
        this.startButtonDisabled = false;
      }, 500);
    }
  }
}
</script>

<style scoped>
@import "../../../assets/css/dashboard.css";
</style>
import Vue from 'vue'
import App from './App.vue'
import router from './router'

Vue.config.productionTip = false
//Vue.use(VueSignalR, 'http://localhost:5500/backend');

new Vue({
  router,
  render: h => h(App),
 /* created() {
    this.$socket.start({
      log: true // Active only in development for debugging.
    });
  },*/
}).$mount('#app')

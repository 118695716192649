<template>
  <div class="main-view">
    <div class="row-wrapper full">
      <div class="widget table">
        <div class="table-title-bar">
          <select v-model="filter" required class="table-select filter">
            <option value="all" disabled selected hidden>Filter</option>
            <option value="all">All</option>
            <option value="2">Connected</option>
            <option value="0">Waiting</option>
          </select>

          <select required class="table-select" disabled>
            <option disabled selected hidden>Actions</option>
            <option value="Example">Example</option>
          </select>
        </div>
        <table>
          <tr>
            <th></th>
            <th>Status</th>
            <th>Username</th>
            <th>Task</th>
            <th>Task State</th>
          </tr>

          <template v-for="account in this.getAccountsByFilter()">
            <tr v-bind:key="account.bssid" style="border-bottom: none;"  @mouseenter="uodateAccountExtraData(account, true);" @mouseleave="uodateAccountExtraData(account, false);">
              <td class="table-checkbox">
                <label class="checkbox">
                  <input type="checkbox" />
                  <span class="checkmark"></span>
                </label>
              </td>
              <td v-bind:class="{colored:true, blue:getState(account.bssid)&&getState(account.bssid).state==2}">{{ getState(account.bssid) ? (getState(account.bssid).state == 0 ? 'WAITING' : (getState(account.bssid).state == 1 ? 'STARTING' : 'RUNNING'))  : '' }}</td>
              <td>{{account.name}}</td>
              <td><img v-if="getState(account.bssid) && getState(account.bssid).task.includes('[ERROR]')" width="16" style="vertical-align: text-bottom; margin-right: 5px;" src="~@/assets/img/action/alert-triangle-outline.svg">{{getState(account.bssid) ? getState(account.bssid).task : ''}}</td>
              <td class="light">{{getState(account.bssid) ? getState(account.bssid).taskState : ''}}</td>
            </tr>
            <tr v-bind:key="'gameDatga_'+account.bssid" v-if="getState(account.bssid) && getState(account.bssid).gameData && getState(account.bssid).gameData.resources"  @mouseenter="uodateAccountExtraData(account, true);" @mouseleave="uodateAccountExtraData(account, false);">
              <td colspan="5">
                <span class="game-data-container">
                  <span>
                    <span><img width="18" style="vertical-align: bottom;" src="~@/assets/img/accounts-tab/gem.png"/>{{getState(account.bssid).gameData.gems}}</span>
                    <span><img width="22" style="vertical-align: bottom;" src="~@/assets/img/accounts-tab/hearth.png"/>{{getState(account.bssid).gameData.sta.current}}/{{getState(account.bssid).gameData.sta.max}}</span>
                  </span>
                  <span style="margin-left: 40px;">
                    <span><img width="18" style="vertical-align: bottom;" src="~@/assets/img/accounts-tab/food.png"/>{{numberToShortForm(getState(account.bssid).gameData.resources.food)}}</span>
                    <span><img width="18" style="vertical-align: bottom;" src="~@/assets/img/accounts-tab/stone.png"/> {{numberToShortForm(getState(account.bssid).gameData.resources.stone)}}</span>
                    <span><img width="18" style="vertical-align: bottom;" src="~@/assets/img/accounts-tab/timber.png"/> {{numberToShortForm(getState(account.bssid).gameData.resources.timber)}}</span>
                    <span><img width="18" style="vertical-align: bottom;" src="~@/assets/img/accounts-tab/ore.png"/> {{numberToShortForm(getState(account.bssid).gameData.resources.ore)}}</span>
                    <span><img width="18" style="vertical-align: bottom;" src="~@/assets/img/accounts-tab/gold.png"/> {{numberToShortForm(getState(account.bssid).gameData.resources.gold)}}</span>
                  </span>
                </span>

                <br/>
                <span v-if="account.showExtra" class="game-data-extra-container">
                  <span>Queue = [ training_toops: {{getState(account.bssid).gameData.queue.trainingTroops}}, building: {{getState(account.bssid).gameData.queue.building}} ]</span>
                  <span>Troops = [ idle: {{getState(account.bssid).gameData.troops.idle}} ]</span>
                  <span>Questline = [ id: {{getState(account.bssid).gameData.questline}} ]</span>
                </span>
              </td>
            </tr>
          </template>

        </table>

        <div class="table-footer">
          <strong>{{getTotal()}}</strong> accounts total, <strong>{{getConnected()}}</strong> connected
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    profile:Object
  },
  data() {
    return {
      filter: 'all'
    }
  },
  methods: {
    getAccountsByFilter: function() {
      if(this.filter == 'all') return this.profile.accounts;

      var items = [];
      this.profile.accounts.forEach((x) => {
        var item = this.getState(x.bssid);
        if(item && item.state == parseInt(this.filter)) items.push(x);
      })

      return items;
    },
    getState: function(id) {
      if(!this.profile?.state?.accounts) return;
      return this.profile.state.accounts[id];
    },
    getTotal: function() {
      return this.profile.accounts.length;
    },
    getConnected: function() {
      var count = 0;
      this.profile.accounts.forEach((x) => {
        var entry = this.getState(x.bssid)
        if(entry && entry.state == 2) count += 1;
      });
      return count;
    },
    numberToShortForm: function(number) {
      if(number > 1000000) return (Math.round(((number / 1000000.00)+ Number.EPSILON) * 100) / 100) + 'M';
      if(number > 10000) return (Math.round(((number / 1000.00)+ Number.EPSILON) * 100) / 100) + 'K';
      return number;
    },
    uodateAccountExtraData: function(account, show) {
      account.showExtra = show;
      this.$forceUpdate();
    }
  }
}
</script>

<style scoped>
@import "../../../assets/css/dashboard.css";
@import "../../../assets/css/table.css";
</style>
const axios = require('axios');

module.exports = {
    url: () => {
        // https://api.afkadvantage.com/ : http://127.0.0.1:5122/
        return 'https://api.afkadvantage.com/';
    },
    get: async (path) => {
        return await axios.get(module.exports.url() + path, {withCredentials: true});
    },
    post: async (path, data) => {
        return await axios.post(module.exports.url() + path, data, {withCredentials: true});
    },
    delete: async (path, data) => {
        return await axios.delete(module.exports.url() + path, { withCredentials: true, data: data });
    },

    sync: async () => {
        return await module.exports.get('sync/all');
    },

    accountSignout: async () => {
        return await module.exports.get('account/auth/signout');
    },

    profilesCreate: async (name, gameId, accounts) => {
        return await module.exports.post('profiles/create', {
            name: name,
            gameId: gameId,
            accounts: accounts
        });
    },
    profileGetConfigs: async() => {
        return await module.exports.get('profiles/configs/get');
    },
    profileDelete: async(id) => {
        return await module.exports.delete('profiles/delete', {
            profile: id,
        });
    },
    profileSetAccounts: async(id, accounts) => {
        return await module.exports.post('profiles/accounts/set', {
            profile: id,
            accounts: accounts
        });
    },

    authLogin: async(email, password, captcha) => {
        return await module.exports.post('account/auth/login', {
            email: email,
            password: password,
            captcha: captcha
        });
    },
    authRegister: async(email, password, username) => {
        return await module.exports.post('account/auth/register', {
            email: email,
            password: password,
            username: username
        });
    },
    passwordRequestReset: async(email, captcha) => {
        return await module.exports.post('account/auth/password/request-reset', {
            email: email,
            captcha: captcha
        });
    },
    passwordSet: async(key, password) => {
        return await module.exports.post('account/auth/password/set', {
            key: key,
            password: password
        });
    },
    changePassword: async(oldPassword, newPassword) => {
        return await module.exports.post('account/auth/password/change', {
            oldPassword: oldPassword,
            newPassword: newPassword
        });
    },

    validation: {
        validatePassword: (data) => {
            if(data == undefined || data == null || data == '' || data.length < 6)
                return false;
            return true;
        },
        validateEmail: (data) => {
            if(data == undefined) return false;
            var atposition=data.indexOf("@");  
            var dotposition=data.lastIndexOf(".");  
            if (atposition<1 || dotposition<atposition+2 || dotposition+2>=data.length){  
              return false;  
            }
            return true;
        },
        validateUsername: (data) => {
            if(data == undefined || data == null || data == '' || data.length < 6)
                return false;
            return true;
        },
        validateCaptcha: (data) => {
            if(data == undefined || data == null || data == '' || data.length < 6)
                return false;
            return true;
        }
    }
}
<template>
  <div class="main-view">
    <div class="row-wrapper full plugin-search">
      <div class="widget">
        <input
          v-model="searchTerm"
          class="widget-search"
          type="text"
          placeholder="Search..."
        />
      </div>
    </div>

    <div class="row-wrapper full">
      <div class="plugins-wrapper">
        <!--

        <div class="widget plugin collapsed">
          <div class="widget-plugin-head">
            <div class="widget-title">Follow plugins</div>
            <div class="widget-text">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
              reprehenderit in voluptate velit esse cillum dolore eu fugiat
              nulla pariatur
            </div>
            <button class="widget-plugin-configure">
              <img
                src="~@/assets/img/dashboard/icon-plugin-configure.svg"
                alt="configure"
              />
              configure
            </button>
          </div>
          <div class="widget-plugin-config-wrapper">
            <div>
              <div class="widget-input">
                <input type="text" value="OnlyQubes" />
                <div class="widget-input-name">Username</div>
              </div>
              <div class="widget-text">
                Enter a player's username which the bots will follow.
              </div>
            </div>
            <div>
              <div class="widget-input">
                <select>
                  <option>Test</option>
                </select>
                <div class="widget-input-name">Select</div>
              </div>
            </div>
            <div class="widget-plugin-checkbox">
              <label class="checkbox">
                <input type="checkbox" checked />
                <span class="checkmark"></span>
              </label>
              <span>This is a checkbox</span>
            </div>
          </div>
        </div>

        <div class="widget plugin">
          <div class="widget-plugin-head">
            <div class="widget-title">Follow plugins</div>
            <div class="widget-text">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
              reprehenderit in voluptate velit esse cillum dolore eu fugiat
              nulla pariatur
            </div>
            <button class="widget-plugin-configure">
              <img
                src="~@/assets/img/dashboard/icon-plugin-configure.svg"
                alt="configure"
              />
              configure
            </button>
          </div>
          <div class="widget-plugin-config-wrapper">
            <div>
              <div class="widget-input">
                <input type="text" value="OnlyQubes" />
                <div class="widget-input-name">Username</div>
              </div>
              <div class="widget-text">
                Enter a player's username which the bots will follow.
              </div>
            </div>
            <div>
              <div class="widget-input">
                <select>
                  <option>Test</option>
                </select>
                <div class="widget-input-name">Select</div>
              </div>
            </div>
            <div class="widget-plugin-checkbox">
              <label class="checkbox">
                <input type="checkbox" checked />
                <span class="checkmark"></span>
              </label>
              <span>This is a checkbox</span>
            </div>
          </div>
        </div>

        <div class="widget plugin collapsed">
          <div class="widget-plugin-head">
            <div class="widget-title">Follow plugins</div>
            <div class="widget-text">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
              reprehenderit in voluptate velit esse cillum dolore eu fugiat
              nulla pariatur
            </div>
            <button class="widget-plugin-configure">
              <img
                src="~@/assets/img/dashboard/icon-plugin-configure.svg"
                alt="configure"
              />
              configure
            </button>
          </div>
          <div class="widget-plugin-config-wrapper">
            <div>
              <div class="widget-input">
                <input type="text" value="OnlyQubes" />
                <div class="widget-input-name">Username</div>
              </div>
              <div class="widget-text">
                Enter a player's username which the bots will follow.
              </div>
            </div>
            <div>
              <div class="widget-input">
                <select>
                  <option>Test</option>
                </select>
                <div class="widget-input-name">Select</div>
              </div>
            </div>
            <div class="widget-plugin-checkbox">
              <label class="checkbox">
                <input type="checkbox" checked />
                <span class="checkmark"></span>
              </label>
              <span>This is a checkbox</span>
            </div>
          </div>
        </div>

        <div class="widget plugin collapsed">
          <div class="widget-plugin-head">
            <div class="widget-title">Follow plugins</div>
            <div class="widget-text">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
              reprehenderit in voluptate velit esse cillum dolore eu fugiat
              nulla pariatur
            </div>
            <button class="widget-plugin-configure">
              <img
                src="~@/assets/img/dashboard/icon-plugin-configure.svg"
                alt="configure"
              />
              configure
            </button>
          </div>
          <div class="widget-plugin-config-wrapper">
            <div>
              <div class="widget-input">
                <input type="text" value="OnlyQubes" />
                <div class="widget-input-name">Username</div>
              </div>
              <div class="widget-text">
                Enter a player's username which the bots will follow.
              </div>
            </div>
            <div>
              <div class="widget-input">
                <select>
                  <option>Test</option>
                </select>
                <div class="widget-input-name">Select</div>
              </div>
            </div>
            <div class="widget-plugin-checkbox">
              <label class="checkbox">
                <input type="checkbox" checked />
                <span class="checkmark"></span>
              </label>
              <span>This is a checkbox</span>
            </div>
          </div>
        </div>


        -->

        <div
          ref="gatherResources"
          v-bind:style="{ display: isInSearch('gatherResources') }"
          class="widget plugin collapsed"
        >
          <div class="widget-plugin-head">
            <div class="widget-title">Resource Gathering</div>
            <div class="widget-text">
              Module that manages resource gathering with troops nearby your kingdom. Here you can select what type of resources will be collected, e.g.: lumber, stone, food, etc.
            </div>
            <div class="widget-plugin-configure">
              <div class="widget-checkbox">
                <label class="checkbox"
                  ><input
                    type="checkbox"
                    v-model="profile.settings.game.gatherResources.enabled"
                    v-on:change="this.pushSettings" /><span
                    class="checkmark"
                  ></span
                ></label>
              </div>
              <button
                class="widget-plugin-configure-button"
                v-on:click="this.toggleCollapse"
              >
                <img
                  src="~@/assets/img/dashboard/icon-plugin-configure.svg"
                  alt="configure"
                />
              </button>
            </div>
          </div>
          <div class="widget-plugin-config-wrapper">
            <div class="widget-plugin-checkbox">
              <label class="checkbox">
                <input
                  type="checkbox"
                  v-model="profile.settings.game.gatherResources.gatherFood"
                  v-on:change="this.pushSettings"
                />
                <span class="checkmark"></span>
              </label>
              <span>Gather Food</span>
            </div>

            <div class="widget-plugin-checkbox">
              <label class="checkbox">
                <input
                  type="checkbox"
                  v-model="profile.settings.game.gatherResources.gatherStone"
                  v-on:change="this.pushSettings"
                />
                <span class="checkmark"></span>
              </label>
              <span>Gather Stone</span>
            </div>

            <div class="widget-plugin-checkbox">
              <label class="checkbox">
                <input
                  type="checkbox"
                  v-model="profile.settings.game.gatherResources.gatherTimber"
                  v-on:change="this.pushSettings"
                />
                <span class="checkmark"></span>
              </label>
              <span>Gather Timber</span>
            </div>

            <div class="widget-plugin-checkbox">
              <label class="checkbox">
                <input
                  type="checkbox"
                  v-model="profile.settings.game.gatherResources.gatherOre"
                  v-on:change="this.pushSettings"
                />
                <span class="checkmark"></span>
              </label>
              <span>Gather Ore</span>
            </div>

            <div class="widget-plugin-checkbox">
              <label class="checkbox">
                <input
                  type="checkbox"
                  v-model="profile.settings.game.gatherResources.gatherGold"
                  v-on:change="this.pushSettings"
                />
                <span class="checkmark"></span>
              </label>
              <span>Gather Gold</span>
            </div>

            <div class="widget-input">
              <select v-model="profile.settings.game.gatherResources.maxDistance" v-on:change="this.pushSettings">
                <option v-bind:value="10">Short</option>
                <option v-bind:value="40">Medium</option>
                <option v-bind:value="100">Long</option>
              </select>
              <div class="widget-input-name">Search Distance</div>
            </div>

            <div class="widget-input">
              <select v-model="profile.settings.game.gatherResources.lowestResourceCount" v-on:change="this.pushSettings">
                <option v-bind:value="1">Lowest Resource Types</option>
                <option v-bind:value="2">2 Lowest Resource Types</option>
                <option v-bind:value="3">3 Lowest Resource Types</option>
                <option v-bind:value="4">4 Lowest Resource Types</option>
                <option v-bind:value="10">Any Resource Types</option>
              </select>
              <div class="widget-input-name">Dynamic Resource Type</div>
            </div>
            <span style="margin-top: -16px; font-size: 13px; opacity: 0.7;">Setting the Dynamic Resource Type allows you to only gather those resources that you are currently lacking the most. E.g.: if you set it to "Lowest Resource Types" then it would only look for the resource that you have the least currently. For example if you had 100 timer, and 1000 of everything else, it would only search for lumber on the map.</span>
          </div>
        </div>

        <div
          ref="autoUpgrade"
          v-bind:style="{ display: isInSearch('autoUpgrade') }"
          class="widget plugin collapsed"
        >
          <div class="widget-plugin-head">
            <div class="widget-title">Auto Upgrade Base</div>
            <div class="widget-text">
              Module that will auto upgrade your base. This will always prioritise the main castle, and other buildins that are required for castle upgrades.
            </div>
            <div class="widget-plugin-configure">
              <div class="widget-checkbox">
                <label class="checkbox"
                  ><input
                    type="checkbox"
                    v-model="profile.settings.game.autoUpgrade.enabled"
                    v-on:change="this.pushSettings" /><span
                    class="checkmark"
                  ></span
                ></label>
              </div>
              <button
                class="widget-plugin-configure-button"
                v-on:click="this.toggleCollapse"
              >
                <img
                  src="~@/assets/img/dashboard/icon-plugin-configure.svg"
                  alt="configure"
                />
              </button>
            </div>
          </div>
          <div class="widget-plugin-config-wrapper"></div>
        </div>

        <div
          ref="autoAdvanceQuestLine"
          v-bind:style="{ display: isInSearch('autoAdvanceQuestLine') }"
          class="widget plugin collapsed"
        >
          <div class="widget-plugin-head">
            <div class="widget-title">Auto Advance Questline</div>
            <div class="widget-text">
              Module that will automatically do the questline. This includes building, researching, upgrading, and doing hero quests, and other various tasks.
            </div>
            <div class="widget-plugin-configure">
              <div class="widget-checkbox">
                <label class="checkbox"
                  ><input
                    type="checkbox"
                    v-model="profile.settings.game.autoAdvanceQuestLine.enabled"
                    v-on:change="this.pushSettings" /><span
                    class="checkmark"
                  ></span
                ></label>
              </div>
              <button
                class="widget-plugin-configure-button"
                v-on:click="this.toggleCollapse"
              >
                <img
                  src="~@/assets/img/dashboard/icon-plugin-configure.svg"
                  alt="configure"
                />
              </button>
            </div>
          </div>
          <div class="widget-plugin-config-wrapper"></div>
        </div>

        <div
          ref="guild"
          v-bind:style="{ display: isInSearch('guild') }"
          class="widget plugin collapsed"
        >
          <div class="widget-plugin-head">
            <div class="widget-title">Guild Manager</div>
            <div class="widget-text">
              Module that will manage activities related to Guilds. This includes joining guilds, opening guild gifts, helping guild members, etc.
            </div>
            <div class="widget-plugin-configure">
              <div class="widget-checkbox">
                <label class="checkbox"
                  ><input
                    type="checkbox"
                    v-model="profile.settings.game.guild.enabled"
                    v-on:change="this.pushSettings" /><span
                    class="checkmark"
                  ></span
                ></label>
              </div>
              <button
                class="widget-plugin-configure-button"
                v-on:click="this.toggleCollapse"
              >
                <img
                  src="~@/assets/img/dashboard/icon-plugin-configure.svg"
                  alt="configure"
                />
              </button>
            </div>
          </div>
          <div class="widget-plugin-config-wrapper">
            <div style="margin-bottom: 30px;">
              <div class="widget-plugin-checkbox">
                <label class="checkbox">
                  <input
                    type="checkbox"
                    v-model="profile.settings.game.guild.autoJoinGuild"
                    v-on:change="this.pushSettings"
                  />
                  <span class="checkmark"></span>
                </label>
                <span>Auto Join Guild</span>
              </div>
              <div class="widget-input">
                <input type="text" v-model="profile.settings.game.guild.guildName" v-on:change="this.pushSettings" />
                <div class="widget-input-name">Guild Name</div>
              </div>
            </div>

            <div class="widget-plugin-checkbox">
              <label class="checkbox">
                <input
                  type="checkbox"
                  v-model="profile.settings.game.guild.openGuildGifts"
                  v-on:change="this.pushSettings"
                />
                <span class="checkmark"></span>
              </label>
              <span>Open Guild Gifts</span>
            </div>

            <div style="margin: 30px 0;">
              <div class="widget-plugin-checkbox">
                <label class="checkbox">
                  <input
                    type="checkbox"
                    v-model="profile.settings.game.guild.autoHunting"
                    v-on:change="this.pushSettings"
                  />
                  <span class="checkmark"></span>
                </label>
                <span>Auto Hunting</span>
              </div>
              <div class="widget-input">
                <select v-model="profile.settings.game.guild.autoHuntingMaxDistance" v-on:change="this.pushSettings">
                  <option v-bind:value="10">Short</option>
                  <option v-bind:value="20">Medium</option>
                  <option v-bind:value="50">Long</option>
                </select>
                <div class="widget-input-name">Monster Search Distance</div>
              </div>
            </div>

            <div class="widget-plugin-checkbox">
              <label class="checkbox">
                <input
                  type="checkbox"
                  v-model="profile.settings.game.guild.helpGuildMembers"
                  v-on:change="this.pushSettings"
                />
                <span class="checkmark"></span>
              </label>
              <span>Help Guild Members</span>
            </div>

            <div style="margin: 30px 0;">
              <div class="widget-plugin-checkbox">
                <label class="checkbox">
                  <input
                    type="checkbox"
                    v-model="profile.settings.game.guild.sendResources"
                    v-on:change="this.pushSettings"
                  />
                  <span class="checkmark"></span>
                </label>
                <span>Send Resources To Guild Members</span>
              </div>
              <div class="widget-input">
                <select v-model="profile.settings.game.guild.sendResourceToRank" v-on:change="this.pushSettings">
                  <option v-bind:value="0">Rank 5</option>
                  <option v-bind:value="1">Rank 4</option>
                  <option v-bind:value="2">Rank 3</option>
                  <option v-bind:value="3">Rank 2</option>
                  <option v-bind:value="4">Rank 1</option>
                </select>
                <div class="widget-input-name">Send Resources to member(s) with Rank</div>
              </div>
              <div class="widget-input">
                <input type="number" v-model.number="profile.settings.game.guild.sendResourcesFoodPercentage" v-bind:min="-1" v-bind:max="100" v-on:change="this.pushSettings" />
                <div class="widget-input-name">Food to send Percentage (percentage of currently available resources)</div>
              </div>
              <div class="widget-input">
                <input type="number" v-model.number="profile.settings.game.guild.sendResourcesStonePercentage" v-bind:min="-1" v-bind:max="100" v-on:change="this.pushSettings" />
                <div class="widget-input-name">Stone to send Percentage (percentage of currently available resources)</div>
              </div>
              <div class="widget-input">
                <input type="number" v-model.number="profile.settings.game.guild.sendResourcesTimberPercentage" v-bind:min="-1" v-bind:max="100" v-on:change="this.pushSettings" />
                <div class="widget-input-name">Timber to send Percentage (percentage of currently available resources)</div>
              </div>
              <div class="widget-input">
                <input type="number" v-model.number="profile.settings.game.guild.sendResourcesOrePercentage" v-bind:min="-1" v-bind:max="100" v-on:change="this.pushSettings" />
                <div class="widget-input-name">Ore to send Percentage (percentage of currently available resources)</div>
              </div>
              <div class="widget-input">
                <input type="number" v-model.number="profile.settings.game.guild.sendResourcesGoldPercentage" v-bind:min="-1" v-bind:max="100" v-on:change="this.pushSettings" />
                <div class="widget-input-name">Gold to send Percentage (percentage of currently available resources)</div>
              </div>
            </div>

          </div>
        </div>

        <div
          ref="autoResearch"
          v-bind:style="{ display: isInSearch('autoResearch') }"
          class="widget plugin collapsed"
        >
          <div class="widget-plugin-head">
            <div class="widget-title">Auto Research</div>
            <div class="widget-text">
              Module that will automatically research in the academy.
            </div>
            <div class="widget-plugin-configure">
              <div class="widget-checkbox">
                <label class="checkbox"
                  ><input
                    type="checkbox"
                    v-model="profile.settings.game.autoResearch.enabled"
                    v-on:change="this.pushSettings" /><span
                    class="checkmark"
                  ></span
                ></label>
              </div>
              <button
                class="widget-plugin-configure-button"
                v-on:click="this.toggleCollapse"
              >
                <img
                  src="~@/assets/img/dashboard/icon-plugin-configure.svg"
                  alt="configure"
                />
              </button>
            </div>
          </div>
          <div class="widget-plugin-config-wrapper"></div>
        </div>

        <div
          ref="shield"
          v-bind:style="{ display: isInSearch('shield') }"
          class="widget plugin collapsed"
        >
          <div class="widget-plugin-head">
            <div class="widget-title">Shield Manager</div>
            <div class="widget-text">
              Module that will manage the kingdom's shields. Will attempt to always have shields enabled when they run out, even optionally for gems if you don't have any shield items.
            </div>
            <div class="widget-plugin-configure">
              <div class="widget-checkbox">
                <label class="checkbox"
                  ><input
                    type="checkbox"
                    v-model="profile.settings.game.shield.enabled"
                    v-on:change="this.pushSettings" /><span
                    class="checkmark"
                  ></span
                ></label>
              </div>
              <button
                class="widget-plugin-configure-button"
                v-on:click="this.toggleCollapse"
              >
                <img
                  src="~@/assets/img/dashboard/icon-plugin-configure.svg"
                  alt="configure"
                />
              </button>
            </div>
          </div>
          <div class="widget-plugin-config-wrapper">

            <div style="margin-bottom: 50px;">
              <div class="widget-plugin-checkbox">
                <label class="checkbox">
                  <input
                    type="checkbox"
                    v-model="profile.settings.game.shield.buyShieldForGems"
                    v-on:change="this.pushSettings"
                  />
                  <span class="checkmark"></span>
                </label>
                <span>Can Buy Shield For Gems</span>
              </div>
            <div>
              <div class="widget-input">
                <select v-model="profile.settings.game.shield.shieldLength" v-on:change="this.pushSettings">
                  <option v-bind:value="0">8 Hours</option>
                  <option v-bind:value="1">24 Hours</option>
                  <option v-bind:value="2">3 Days</option>
                  <option v-bind:value="3">7 Days</option>
                  <option v-bind:value="4">14 Days</option>
                </select>
                <div class="widget-input-name">Shield Duration</div>
              </div>
            </div>
            </div>
          </div>
        </div>

        <div
          ref="healTroops"
          v-bind:style="{ display: isInSearch('healTroops') }"
          class="widget plugin collapsed"
        >
          <div class="widget-plugin-head">
            <div class="widget-title">Troop Healing Manager</div>
            <div class="widget-text">
              Module that will automatically heal troops when available.
            </div>
            <div class="widget-plugin-configure">
              <div class="widget-checkbox">
                <label class="checkbox"
                  ><input
                    type="checkbox"
                    v-model="profile.settings.game.healTroops.enabled"
                    v-on:change="this.pushSettings" /><span
                    class="checkmark"
                  ></span
                ></label>
              </div>
              <button
                class="widget-plugin-configure-button"
                v-on:click="this.toggleCollapse"
              >
                <img
                  src="~@/assets/img/dashboard/icon-plugin-configure.svg"
                  alt="configure"
                />
              </button>
            </div>
          </div>
          <div class="widget-plugin-config-wrapper">

            <div style="margin-bottom: 50px;">
              <div class="widget-plugin-checkbox">
                <label class="checkbox">
                  <input
                    type="checkbox"
                    v-model="profile.settings.game.healTroops.useSpeedups"
                    v-on:change="this.pushSettings"
                  />
                  <span class="checkmark"></span>
                </label>
                <span>Use Speedups</span>
              </div>
            </div>
          </div>
        </div>

        <div
          ref="autoShelter"
          v-bind:style="{ display: isInSearch('autoShelter') }"
          class="widget plugin collapsed"
        >
          <div class="widget-plugin-head">
            <div class="widget-title">Troop Shelter Manager</div>
            <div class="widget-text">
              Module that will automatically shelter troops when available.
            </div>
            <div class="widget-plugin-configure">
              <div class="widget-checkbox">
                <label class="checkbox"
                  ><input
                    type="checkbox"
                    v-model="profile.settings.game.autoShelter.enabled"
                    v-on:change="this.pushSettings" /><span
                    class="checkmark"
                  ></span
                ></label>
              </div>
              <button
                class="widget-plugin-configure-button"
                v-on:click="this.toggleCollapse"
              >
                <img
                  src="~@/assets/img/dashboard/icon-plugin-configure.svg"
                  alt="configure"
                />
              </button>
            </div>
          </div>
          <div class="widget-plugin-config-wrapper">
            <div class="widget-input">
                <select v-model="profile.settings.game.autoShelter.shelterDuration" v-on:change="this.pushSettings">
                  <option v-bind:value="1">1 Hour</option>
                  <option v-bind:value="4">4 Hours</option>
                  <option v-bind:value="8">8 Hours</option>
                  <option v-bind:value="12">12 Hours</option>
                </select>
                <div class="widget-input-name">Shelter Duration</div>
              </div>
          </div>
        </div>

        <div
          ref="autoTrainTroops"
          v-bind:style="{ display: isInSearch('autoTrainTroops') }"
          class="widget plugin collapsed"
        >
          <div class="widget-plugin-head">
            <div class="widget-title">Troop Training Manager</div>
            <div class="widget-text">
              Module that will automatically train troops to the desired amounts.
            </div>
            <div class="widget-plugin-configure">
              <div class="widget-checkbox">
                <label class="checkbox"
                  ><input
                    type="checkbox"
                    v-model="profile.settings.game.autoTrainTroops.enabled"
                    v-on:change="this.pushSettings" /><span
                    class="checkmark"
                  ></span
                ></label>
              </div>
              <button
                class="widget-plugin-configure-button"
                v-on:click="this.toggleCollapse"
              >
                <img
                  src="~@/assets/img/dashboard/icon-plugin-configure.svg"
                  alt="configure"
                />
              </button>
            </div>
          </div>
          <div class="widget-plugin-config-wrapper">

            <p>
              This module will detect how many troops are missing from your desired amount and will attempt to train the required amount.<br/>
              <strong>Set the setting to -1 in order to not train any troops of a particular type.</strong>
            </p>
            <div>
              <strong>Tier 1 Troops</strong>
              <div class="widget-input">
                <input type="number" v-model.number="profile.settings.game.autoTrainTroops.desiredGrunt" v-bind:min="-1" v-bind:max="100000000" v-on:change="keepNumberInBounds(profile.settings.game.autoTrainTroops.desiredGrunt, (e)=>profile.settings.game.autoTrainTroops.desiredGrunt = e)" />
                <div class="widget-input-name">Desired Grunt Amount</div>
              </div>
              <div class="widget-input">
                <input type="number" v-model.number="profile.settings.game.autoTrainTroops.desiredArcher" v-bind:min="-1" v-bind:max="100000000" v-on:change="keepNumberInBounds(profile.settings.game.autoTrainTroops.desiredArcher, (e)=>profile.settings.game.autoTrainTroops.desiredArcher = e)" />
                <div class="widget-input-name">Desired Archer Amount</div>
              </div>
              <div class="widget-input">
                <input type="number" v-model.number="profile.settings.game.autoTrainTroops.desiredCataphract" v-bind:min="-1" v-bind:max="100000000" v-on:change="keepNumberInBounds(profile.settings.game.autoTrainTroops.desiredCataphract, (e)=>profile.settings.game.autoTrainTroops.desiredCataphract = e)" />
                <div class="widget-input-name">Desired Cataphract Amount</div>
              </div>
              <div class="widget-input">
                <input type="number" v-model.number="profile.settings.game.autoTrainTroops.desiredBalista" v-bind:min="-1" v-bind:max="100000000" v-on:change="keepNumberInBounds(profile.settings.game.autoTrainTroops.desiredBalista, (e)=>profile.settings.game.autoTrainTroops.desiredBalista = e)" />
                <div class="widget-input-name">Desired Balista Amount</div>
              </div>
            </div>

            <div>
              <strong>Tier 2 Troops</strong>
              <div class="widget-input">
                <input type="number" v-model.number="profile.settings.game.autoTrainTroops.desiredGladiator" v-bind:min="-1" v-bind:max="100000000" v-on:change="keepNumberInBounds(profile.settings.game.autoTrainTroops.desiredGladiator, (e)=>profile.settings.game.autoTrainTroops.desiredGladiator = e)" />
                <div class="widget-input-name">Desired Gladiator Amount</div>
              </div>
              <div class="widget-input">
                <input type="number" v-model.number="profile.settings.game.autoTrainTroops.desiredSharpshooter" v-bind:min="-1" v-bind:max="100000000" v-on:change="keepNumberInBounds(profile.settings.game.autoTrainTroops.desiredSharpshooter, (e)=>profile.settings.game.autoTrainTroops.desiredSharpshooter = e)" />
                <div class="widget-input-name">Desired Sharpshooter Amount</div>
              </div>
              <div class="widget-input">
                <input type="number" v-model.number="profile.settings.game.autoTrainTroops.desiredReptilianRider" v-bind:min="-1" v-bind:max="100000000" v-on:change="keepNumberInBounds(profile.settings.game.autoTrainTroops.desiredReptilianRider, (e)=>profile.settings.game.autoTrainTroops.desiredReptilianRider = e)" />
                <div class="widget-input-name">Desired Reptilian Rider Amount</div>
              </div>
              <div class="widget-input">
                <input type="number" v-model.number="profile.settings.game.autoTrainTroops.desiredCatapult" v-bind:min="-1" v-bind:max="100000000" v-on:change="keepNumberInBounds(profile.settings.game.autoTrainTroops.desiredCatapult, (e)=>profile.settings.game.autoTrainTroops.desiredCatapult = e)" />
                <div class="widget-input-name">Desired Catapult Amount</div>
              </div>
            </div>

            <div>
              <strong>Tier 3 Troops</strong>
              <div class="widget-input">
                <input type="number" v-model.number="profile.settings.game.autoTrainTroops.desiredRoyalGuard" v-bind:min="-1" v-bind:max="100000000" v-on:change="keepNumberInBounds(profile.settings.game.autoTrainTroops.desiredRoyalGuard, (e)=>profile.settings.game.autoTrainTroops.desiredRoyalGuard = e)" />
                <div class="widget-input-name">Desired Royal Guard Amount</div>
              </div>
              <div class="widget-input">
                <input type="number" v-model.number="profile.settings.game.autoTrainTroops.desiredStealthSniper" v-bind:min="-1" v-bind:max="100000000" v-on:change="keepNumberInBounds(profile.settings.game.autoTrainTroops.desiredStealthSniper, (e)=>profile.settings.game.autoTrainTroops.desiredStealthSniper = e)" />
                <div class="widget-input-name">Desired Stealth Sniper" Amount</div>
              </div>
              <div class="widget-input">
                <input type="number" v-model.number="profile.settings.game.autoTrainTroops.desiredRoyalCavalry" v-bind:min="-1" v-bind:max="100000000" v-on:change="keepNumberInBounds(profile.settings.game.autoTrainTroops.desiredRoyalCavalry, (e)=>profile.settings.game.autoTrainTroops.desiredRoyalCavalry = e)" />
                <div class="widget-input-name">Desired Royal Cavalry Amount</div>
              </div>
              <div class="widget-input">
                <input type="number" v-model.number="profile.settings.game.autoTrainTroops.desiredFireTrebuchet" v-bind:min="-1" v-bind:max="100000000" v-on:change="keepNumberInBounds(profile.settings.game.autoTrainTroops.desiredFireTrebuchet, (e)=>profile.settings.game.autoTrainTroops.desiredFireTrebuchet = e)" />
                <div class="widget-input-name">Desired Fire Trebuchet Amount</div>
              </div>
            </div>

            <div>
              <strong>Tier 4 Troops</strong>
              <div class="widget-input">
                <input type="number" v-model.number="profile.settings.game.autoTrainTroops.desiredHeroicFighter" v-bind:min="-1" v-bind:max="100000000" v-on:change="keepNumberInBounds(profile.settings.game.autoTrainTroops.desiredHeroicFighter, (e)=>profile.settings.game.autoTrainTroops.desiredHeroicFighter = e)" />
                <div class="widget-input-name">Desired Heroic Fighter Amount</div>
              </div>
              <div class="widget-input">
                <input type="number" v-model.number="profile.settings.game.autoTrainTroops.desiredHeroicCannoneer" v-bind:min="-1" v-bind:max="100000000" v-on:change="keepNumberInBounds(profile.settings.game.autoTrainTroops.desiredHeroicCannoneer, (e)=>profile.settings.game.autoTrainTroops.desiredHeroicCannoneer = e)" />
                <div class="widget-input-name">Desired Heroic Cannoneer Amount</div>
              </div>
              <div class="widget-input">
                <input type="number" v-model.number="profile.settings.game.autoTrainTroops.desiredAncientDrakeRider" v-bind:min="-1" v-bind:max="100000000" v-on:change="keepNumberInBounds(profile.settings.game.autoTrainTroops.desiredAncientDrakeRider, (e)=>profile.settings.game.autoTrainTroops.desiredAncientDrakeRider = e)" />
                <div class="widget-input-name">Desired Ancient Drake Rider Amount</div>
              </div>
              <div class="widget-input">
                <input type="number" v-model.number="profile.settings.game.autoTrainTroops.desiredDestroyer" v-bind:min="-1" v-bind:max="100000000" v-on:change="keepNumberInBounds(profile.settings.game.autoTrainTroops.desiredDestroyer, (e)=>profile.settings.game.autoTrainTroops.desiredDestroyer = e)" />
                <div class="widget-input-name">Desired Destroyer Amount</div>
              </div>
            </div>

          </div>
        </div>

        <div
          ref="autoQuest"
          v-bind:style="{ display: isInSearch('autoQuest') }"
          class="widget plugin collapsed"
        >
          <div class="widget-plugin-head">
            <div class="widget-title">Auto Quest</div>
            <div class="widget-text">
              Module that will automatically start and complete/claim daily, turf, admin, guild, and VIP quests.
            </div>
            <div class="widget-plugin-configure">
              <div class="widget-checkbox">
                <label class="checkbox"
                  ><input
                    type="checkbox"
                    v-model="profile.settings.game.autoQuest.enabled"
                    v-on:change="this.pushSettings" /><span
                    class="checkmark"
                  ></span
                ></label>
              </div>
              <button
                class="widget-plugin-configure-button"
                v-on:click="this.toggleCollapse"
              >
                <img
                  src="~@/assets/img/dashboard/icon-plugin-configure.svg"
                  alt="configure"
                />
              </button>
            </div>
          </div>
          <div class="widget-plugin-config-wrapper">

            <div style="margin-bottom: 20px;">
              <div class="widget-plugin-checkbox">
                <label class="checkbox">
                  <input
                    type="checkbox"
                    v-model="profile.settings.game.autoQuest.dailyQuests"
                    v-on:change="this.pushSettings"
                  />
                  <span class="checkmark"></span>
                </label>
                <span>Daily Quests</span>
              </div>
            </div>

            <div style="margin-bottom: 20px;">
              <div class="widget-plugin-checkbox">
                <label class="checkbox">
                  <input
                    type="checkbox"
                    v-model="profile.settings.game.autoQuest.turfQuests"
                    v-on:change="this.pushSettings"
                  />
                  <span class="checkmark"></span>
                </label>
                <span>Turf Quests</span>
              </div>
            </div>

            <div style="margin-bottom: 20px;">
              <div class="widget-plugin-checkbox">
                <label class="checkbox">
                  <input
                    type="checkbox"
                    v-model="profile.settings.game.autoQuest.adminQuests"
                    v-on:change="this.pushSettings"
                  />
                  <span class="checkmark"></span>
                </label>
                <span>Admin Quests</span>
              </div>
            </div>

            <div style="margin-bottom: 20px;">
              <div class="widget-plugin-checkbox">
                <label class="checkbox">
                  <input
                    type="checkbox"
                    v-model="profile.settings.game.autoQuest.guildQuests"
                    v-on:change="this.pushSettings"
                  />
                  <span class="checkmark"></span>
                </label>
                <span>Guild Quests</span>
              </div>
            </div>

            <div style="margin-bottom: 50px;">
              <div class="widget-plugin-checkbox">
                <label class="checkbox">
                  <input
                    type="checkbox"
                    v-model="profile.settings.game.autoQuest.vipQuests"
                    v-on:change="this.pushSettings"
                  />
                  <span class="checkmark"></span>
                </label>
                <span>Vip Quests</span>
              </div>
            </div>
            

          </div>
        </div>

        <div
          ref="autoHeroStage"
          v-bind:style="{ display: isInSearch('autoHeroStage') }"
          class="widget plugin collapsed"
        >
          <div class="widget-plugin-head">
            <div class="widget-title">Auto Hero Stage</div>
            <div class="widget-text">
              Module that will automatically complete hero stages.
            </div>
            <div class="widget-plugin-configure">
              <div class="widget-checkbox">
                <label class="checkbox"
                  ><input
                    type="checkbox"
                    v-model="profile.settings.game.autoHeroStage.enabled"
                    v-on:change="this.pushSettings" /><span
                    class="checkmark"
                  ></span
                ></label>
              </div>
              <button
                class="widget-plugin-configure-button"
                v-on:click="this.toggleCollapse"
              >
                <img
                  src="~@/assets/img/dashboard/icon-plugin-configure.svg"
                  alt="configure"
                />
              </button>
            </div>
          </div>
          <div class="widget-plugin-config-wrapper">
          </div>
        </div>

        <div
          ref="autoUseSpeedups"
          v-bind:style="{ display: isInSearch('autoUseSpeedups') }"
          class="widget plugin collapsed"
        >
          <div class="widget-plugin-head">
            <div class="widget-title">Auto Use Speedups</div>
            <div class="widget-text">
              Module that will automatically use any available speedups for building, training, reseraching.
            </div>
            <div class="widget-plugin-configure">
              <div class="widget-checkbox">
                <label class="checkbox"
                  ><input
                    type="checkbox"
                    v-model="profile.settings.game.autoSpeedUp.enabled"
                    v-on:change="this.pushSettings" /><span
                    class="checkmark"
                  ></span
                ></label>
              </div>
              <button
                class="widget-plugin-configure-button"
                v-on:click="this.toggleCollapse"
              >
                <img
                  src="~@/assets/img/dashboard/icon-plugin-configure.svg"
                  alt="configure"
                />
              </button>
            </div>
          </div>
          <div class="widget-plugin-config-wrapper">

            <div style="margin-bottom: 20px;">
              <div class="widget-plugin-checkbox">
                <label class="checkbox">
                  <input
                    type="checkbox"
                    v-model="profile.settings.game.autoSpeedUp.speedUpResearch"
                    v-on:change="this.pushSettings"
                  />
                  <span class="checkmark"></span>
                </label>
                <span>Speedup Research</span>
              </div>
            </div>

            <div style="margin-bottom: 20px;">
              <div class="widget-plugin-checkbox">
                <label class="checkbox">
                  <input
                    type="checkbox"
                    v-model="profile.settings.game.autoSpeedUp.speedUpBuilding"
                    v-on:change="this.pushSettings"
                  />
                  <span class="checkmark"></span>
                </label>
                <span>Speedup Building</span>
              </div>
            </div>
            
            <div style="margin-bottom: 20px;">
              <div class="widget-plugin-checkbox">
                <label class="checkbox">
                  <input
                    type="checkbox"
                    v-model="profile.settings.game.autoSpeedUp.speedUpTraining"
                    v-on:change="this.pushSettings"
                  />
                  <span class="checkmark"></span>
                </label>
                <span>Speedup Training</span>
              </div>
            </div>

          </div>
        </div>

        <div
          ref="autoShelter"
          v-bind:style="{ display: isInSearch('autoShelter') }"
          class="widget plugin collapsed"
        >
          <div class="widget-plugin-head">
            <div class="widget-title">Anti-Ban</div>
            <div class="widget-text">
              Module related to anti-ban settings, such as breaks and various delays.
            </div>
            <div class="widget-plugin-configure">
              <div class="widget-checkbox">
                <label class="checkbox"
                  ><input
                    type="checkbox"
                    v-model="profile.settings.generic.antiBan"
                    v-on:change="this.pushSettings" /><span
                    class="checkmark"
                  ></span
                ></label>
              </div>
              <button
                class="widget-plugin-configure-button"
                v-on:click="this.toggleCollapse"
              >
                <img
                  src="~@/assets/img/dashboard/icon-plugin-configure.svg"
                  alt="configure"
                />
              </button>
            </div>
          </div>
          <div class="widget-plugin-config-wrapper">
            <div class="widget-input">
                <select v-model="profile.settings.generic.longBreaksPerDay" v-on:change="this.pushSettings">
                  <option v-bind:value="1">1 Long Break per Day</option>
                  <option v-bind:value="2">2 Long Breaks per Day</option>
                  <option v-bind:value="3">3 Long Breaks per Day</option>
                </select>
                <div class="widget-input-name">Long Breaks per Day</div>
            </div>
            <div class="widget-input">
                <select v-model="profile.settings.generic.longBreakMinutes" v-on:change="this.pushSettings">
                  <option v-bind:value="120">~2 Hours per Break</option>
                  <option v-bind:value="180">~3 Hours per Break</option>
                  <option v-bind:value="300">~5 Hours per Break</option>
                </select>
                <div class="widget-input-name">Long Break duration</div>
            </div>

            <div class="widget-input">
                <select v-model="profile.settings.generic.shortBreaksPerDay" v-on:change="this.pushSettings">
                  <option v-bind:value="10">10</option>
                  <option v-bind:value="15">15</option>
                  <option v-bind:value="20">20</option>
                </select>
                <div class="widget-input-name">Short Breaks per Day</div>
            </div>
            <div class="widget-input">
                <select v-model="profile.settings.generic.shortBreakMinutes" v-on:change="this.pushSettings">
                  <option v-bind:value="1">~1 Minute per Break</option>
                  <option v-bind:value="2">~2 Minutes per Break</option>
                  <option v-bind:value="4">~4 Minutes per Break</option>
                </select>
                <div class="widget-input-name">Short Break duration</div>
            </div>
            
            <div class="widget-input">
                <select v-model="profile.settings.generic.miniBreaksPerDay" v-on:change="this.pushSettings">
                  <option v-bind:value="10">10</option>
                  <option v-bind:value="20">20</option>
                  <option v-bind:value="30">30</option>
                </select>
                <div class="widget-input-name">Mini Breaks per Day</div>
            </div>
            <div class="widget-input">
                <select v-model="profile.settings.generic.miniBreakSeconds" v-on:change="this.pushSettings">
                  <option v-bind:value="10">~10 Seconds per Break</option>
                  <option v-bind:value="30">~30 Seconds per Break</option>
                  <option v-bind:value="50">~50 Seconds per Break</option>
                </select>
                <div class="widget-input-name">Mini Break duration</div>
            </div>
            

          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import SocketApi from "../../../scripts/socketApi";
export default {
  props: {
    profile: Object,
  },
  data() {
    return {
      searchTerm: undefined,
    };
  },
  methods: {
    toggleCollapse: function (event) {
      var el =
        event.target.parentElement.parentElement.parentElement.parentElement;
      if (el.classList.contains("collapsed")) el.classList.remove("collapsed");
      else el.classList.add("collapsed");
    },
    pushSettings: function () {
      SocketApi.pushSettings(this.profile.settings, this.$route.params.id);
    },
    keepNumberInBounds: function(val, e) {
      if(val == '' || val <= 0) e(-1);
      this.pushSettings();
    },
    isInSearch: function (e) {
      if (this.searchTerm == "" || this.searchTerm == undefined) return "block";

      var cleanTerm = this.searchTerm.toLowerCase().replace(/\s+/g, "");
      if (
        this.$refs[e]
          .querySelector(".widget-title")
          .innerText.toLowerCase()
          .replace(/\s+/g, "")
          .includes(cleanTerm) ||
        this.$refs[e]
          .querySelector(".widget-text")
          .innerText.toLowerCase()
          .replace(/\s+/g, "")
          .includes(cleanTerm) ||
        this.$refs[e]
          .querySelector(".widget-plugin-config-wrapper")
          .innerText.toLowerCase()
          .replace(/\s+/g, "")
          .includes(cleanTerm)
      )
        return "block";
      return "none";
    },
  },
};
</script>

<style scoped>
@import "../../../assets/css/dashboard.css";
</style>
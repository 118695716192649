<template>
  <nav class="navbar">
    <select
      v-if="showProfile"
      required
      class="navbar-select-profile"
      v-bind:value="profile ? profile.id : ''"
      v-on:change="onProfileSelectionChanged"
      ref="navbar-profile-input"
    >
      <option value="" disabled selected hidden>No profile selected...</option>
      <option
        v-for="profile in profiles"
        v-bind:key="profile.id"
        v-bind:value="profile.id"
      >
        {{ profile.name }}
      </option>
      <option value="create-new-profile">Create new profile...</option>
    </select>
    <div v-else></div>

    <ul class="navbar-buttons">
      <li
        @mouseover="showNotifications = true"
        @mouseleave="showNotifications = false"
        class="navbar-button"
      >
        <img src="@/assets/img/navbar/bell.svg" alt="bell" />
        <div
          v-bind:class="{
            'navbar-popup': true,
            hidden: !this.showNotifications,
          }"
        >
          <div class="navbar-notifications-empty-title">
            No new notifications
          </div>
          <div class="navbar-notifications-empty-description">
            A blue cirlce will appear on the bell icon once you receive any
            notifications.
          </div>

          <!-- 
          <div class="navbar-notification">
            <button class="navbar-notification-close"></button>
            <div class="navbar-notification-title">License renewed</div>
            <div class="navbar-notification-content">
              Your license has been automatically renewed on 16/07/202
            </div>
          </div>
          -->
        </div>
      </li>
      <li
        @mouseover="showHelp = true"
        @mouseleave="showHelp = false"
        class="navbar-button"
      >
        <img src="@/assets/img/navbar/help-circle.svg" alt="help-circle" />
        <div v-bind:class="{ 'navbar-popup': true, hidden: !this.showHelp }">
          <div class="navbar-help-title">Help</div>
          <a class="navbar-popup-href" href="https://panel.afkadvantage.com/discord" target="_blank">
            <img src="@/assets/img/navbar/icon-discord.svg" alt="discord" />
            Support Discord
          </a>
          <a class="navbar-popup-href" href="https://help.afkadvantage.com" target="_blank">
            <img src="@/assets/img/navbar/icon-film.svg" alt="tutorials" />
            Tutorials
          </a>
          <a
            class="navbar-popup-href"
            target="_blank"
            href="/policy/terms-of-service"
          >
            <img src="@/assets/img/navbar/icon-external-link.svg" alt="tos" />
            Terms of Service
          </a>
        </div>
      </li>
      <li
        @mouseover="showUserNotifications = true"
        @mouseleave="showUserNotifications = false"
        class="navbar-button"
      >
        <img src="@/assets/img/navbar/user.svg" alt="user" />
        <div
          v-bind:class="{
            'navbar-popup': true,
            hidden: !this.showUserNotifications,
          }"
        >
          <div class="navbar-user-username">{{ name }}</div>
          <div class="navbar-user-plan-wrapper">
            <div class="navbar-user-plan">{{ plan }} Plan</div>
            <div class="navbar-user-plan-dot"></div>
            <div class="navbar-user-plan">{{ daysLeft }} days left</div>
          </div>
          <router-link class="navbar-popup-href" to="/account/overview"
            >Profile</router-link
          >
          <a class="navbar-popup-href" href="https://panel.afkadvantage.com/prices"
            >Prices / Billing</a
          >
          <router-link class="navbar-popup-href" to="/account/signout"
            >Sign out</router-link
          >
        </div>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  data() {
    return {
      showNotifications: false,
      showHelp: false,
      showUserNotifications: false,
    };
  },
  props: {
    showProfile: Boolean,
    profiles: Array,
    profile: Object,
    account: Object,
  },
  computed: {
    name() {
      return this.account?.auth?.name;
    },
    plan() {
      return this.account?.subscription?.plan;
    },
    daysLeft() {
      return this.account?.subscription?.daysLeft;
    },
  },
  methods: {
    onProfileSelectionChanged: function () {
      if (this.$refs["navbar-profile-input"].value == "create-new-profile")
        return this.$router.push("/profile/create");

      return this.$router.push(
        "/" + this.$refs["navbar-profile-input"].value + "/dash"
      );
    },
  },
};
</script>
<template>
  <div v-if="profiles && profiles.length > 0" class="main-view">
    <div class="row-wrapper quarter">
      <router-link to="/profile/create">
        <div class="widget create-profile">
            <div class="widget-content">
            <div class="widget-title create-profile">Create new profile</div>
            <div class="widget-text profile">
                <p>Click here to create a new server profile.</p>
                <p>This will not stop any running instances.</p>
            </div>
            </div>
        </div>
      </router-link>

      <router-link v-for="profile in profiles" v-bind:key="profile.id" v-bind:to="'/'+profile.id+'/dash'" style="margin-bottom: 30px;">
        <div v-bind:class="{ widget:true, profile:true, inactive:!profile.active}">
            <div class="widget-content">
            <div class="widget-title profile">
                {{profile.name}} <em>- {{profile.game.name}}</em>
            </div>
            <div class="widget-text profile">
                <p v-if="profile.active">
                Currently <strong>active</strong>, connected
                <strong>{{profile.connected}}</strong> bots.
                </p>
                <p v-else>
                    Currently not active.
                </p>

                <p v-if="profile.active">Started <strong>{{profile.lastStarted}}</strong></p>
                <p v-else>Last active {{profile.lastActive.startsWith('0001-') ? 'never.' : new Date(profile.lastActive).toISOString().split('T')[0]}}</p>
            </div>
            </div>
        </div>
      </router-link>
    </div>
  </div>
  <Empty v-else/>
</template>

<script>
import Empty from './DashboardEmpty';
export default {
    components: {
        Empty
    },
    props: {
        profiles: Array
    }
}
</script>
<style scoped>
@import '../../../assets/css/dashboard.css';
</style>
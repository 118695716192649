<template>
  <div class="main-view">
    <div class="row-wrapper quarter">
      <div class="widget discord">
        <div class="widget-content">
          <div class="widget-title">Join our Discord 🎉</div>
          <a href="https://panel.afkadvantage.com/discord" target="_blank" class="discord-link">Click here to join</a>
          <label for="inputAuthorizationToken">Authorization token</label>
          <input
            class="discord-auth-token"
            type="text"
            id="inputAuthorizationToken"
            readonly
          />
          <span class="discord-hint"
            >Send this to the authorization bot to gain a rank.</span
          >
        </div>
      </div>
    </div>

    <div class="row-wrapper full">
      <div class="widget webhook">
        <div class="widget-content">
          <div class="widget-title">Notifications / Webhooks</div>
          <div class="widget-text">
            Get event notifications directly through discord.
            <a href="https://help.afkadvantage.com/features/discord-webhooks" target="_blank">Click here</a> to learn more about discord webhooks.
          </div>
          <div class="widget-input">
            <input type="text" placeholder="Full webhook url" v-model="profile.settings.generic.discordWebhookUrl" v-on:change="pushSettings" />
            <div class="widget-input-name">Webhook URL</div>
          </div>
          <div class="webhook-checkboxes">
            <div class="webhook-checkbox">
              <label class="checkbox">
                <input type="checkbox" v-model="profile.settings.generic.discordNotifyOnStart" v-on:change="pushSettings"/>
                <span class="checkmark"></span>
              </label>
              Bot started
            </div>
            <div class="webhook-checkbox">
              <label class="checkbox">
                <input type="checkbox" v-model="profile.settings.generic.discordNotifyOnStop" v-on:change="pushSettings" />
                <span class="checkmark"></span>
              </label>
              Bot stopped
            </div>
            <div class="webhook-checkbox">
              <label class="checkbox">
                <input type="checkbox" v-model="profile.settings.generic.discordNotifyOnTaskCompleted" v-on:change="pushSettings" />
                <span class="checkmark"></span>
              </label>
              Task completed
            </div>
            <div class="webhook-checkbox">
              <label class="checkbox">
                <input type="checkbox" v-model="profile.settings.generic.discordNotifyOnTaskError" v-on:change="pushSettings" />
                <span class="checkmark"></span>
              </label>
              Task failed
            </div>
            <div class="webhook-checkbox">
              <label class="checkbox">
                <input type="checkbox" v-model="profile.settings.generic.discordNotifyOnStuck" v-on:change="pushSettings" />
                <span class="checkmark"></span>
              </label>
              On error
            </div>
            <div class="webhook-checkbox">
              <label class="checkbox">
                <input type="checkbox" v-model="profile.settings.generic.discordNotifyOnAttack" v-on:change="pushSettings" />
                <span class="checkmark"></span>
              </label>
              On Getting Raided
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SocketApi from '../../../scripts/socketApi';

export default {
  props: {
    profile : Object
  },
  methods: {
    pushSettings: function() {
      SocketApi.pushSettings(this.profile.settings, this.$route.params.id);
    }
  }
}
</script>

<style scoped>
@import "../../../assets/css/dashboard.css";
</style>